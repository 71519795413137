import React, { createContext, useContext, useState } from 'react';
import Cookies from 'js-cookie';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(!!Cookies.get('session_token')); // Verifica si el usuario tiene una cookie de sesión válida

  const login = () => {
    // Verificar si hay una cookie de sesión activa al cargar el componente
    const sessionCookie = Cookies.get('session_token'); // Suponiendo que la cookie de sesión se almacena en localStorage
    if (sessionCookie) {
      // Si hay una cookie de sesión activa, redirigir al usuario a la página principal ("/main")
      setIsLoggedIn(true);
    }
  };

  const logout = () => {
    // Aquí podrías implementar la lógica de cierre de sesión, como eliminar la cookie de sesión
    setIsLoggedIn(false);
    Cookies.remove('session_token');
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};