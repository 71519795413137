import React from 'react';
import { FaEdit, FaTrash, FaInfoCircle } from 'react-icons/fa';

const DataTable = ({
  getTableProps,
  getTableBodyProps,
  headerGroups,
  page,
  prepareRow,
  handleEditRow,
  handleRemoveRow,
  editRowIndex,
  renderEditCell,
  renderCellData,
  toggleRowInfo,
  selectedRowIndex,
  moreInfoText,
  moreInfoKey,
  columnsConfig,
  handleInputChange,
  editedRowData,
  handleInputJsonChange,
  rowInfo
}) => {
  return (
    <table {...getTableProps()} className="paginated-table">
      <thead>
        {headerGroups.map(headerGroup => {
          const { key, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps();
          return (
            <tr key={key} {...restHeaderGroupProps}>
              {headerGroup.headers.map(column => {
                const { key, ...restHeaderProps } = column.getHeaderProps(column.getSortByToggleProps());
                return (
                  <th key={key} {...restHeaderProps}>
                    {column.render('Header')}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? ' 🔽'
                          : ' 🔼'
                        : ''}
                    </span>
                  </th>
                );
              })}
              <th>Actions</th>
            </tr>
          );
        })}
      </thead>
      <tbody {...getTableBodyProps()}>
        {page.map((row, i) => {
          prepareRow(row);
          const { key, ...restRowProps } = row.getRowProps();
          return (
            <React.Fragment key={key}>
              <tr {...restRowProps}>
                {row.cells.map(cell => {
                  const { key, ...restCellProps } = cell.getCellProps();
                  console.log(`cell.column.id:${cell.column.id}|i:${i}|editRowIndex:${editRowIndex}`)
                  return (
                    <td key={key} {...restCellProps}>
                        {editRowIndex === i && cell.column.id !== 'moreInfo'
                          ? renderEditCell(cell.column, editedRowData[cell.column.id], (e) => handleInputChange(e, cell.column.id), cell.column.isEditable)
                          : cell.column.id === 'moreInfo'
                            ? <button onClick={() => toggleRowInfo(i, row.original[moreInfoKey])} disabled={(editRowIndex !== null && editRowIndex !== i)}>
                                <FaInfoCircle /> {moreInfoText}</button>
                            : renderCellData(cell.value, cell.column.dataType)
                        }
                      </td>
                  );
                })}
                <td>
                  <button onClick={() => handleEditRow(i)} title="Edit">
                    <FaEdit />
                  </button>
                  <button onClick={() => handleRemoveRow(row)} title="Delete">
                    <FaTrash />
                  </button>
                </td>
              </tr>
              {selectedRowIndex === i && rowInfo && (
                  <tr>
                    <td colSpan={columnsConfig.length + 1}>
                      <div className="info-card">
                        <h3>{moreInfoText}</h3>
                        {editRowIndex === i ? (
                          <textarea
                            value={JSON.stringify(editedRowData[moreInfoKey], null, 2)}
                            onChange={(e) => handleInputJsonChange(e, moreInfoKey)}
                          />
                        ) : (
                          <pre>{JSON.stringify(rowInfo, null, 2)}</pre>
                        )}
                      </div>
                    </td>
                  </tr>
                )}
            </React.Fragment>
          );
        })}
      </tbody>
    </table>
  );
};

export default DataTable;
