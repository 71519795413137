import React from 'react';
import { FaSave, FaPlus, FaSync } from 'react-icons/fa';

const TableControls = ({ handleUpdateRow, setShowCreateForm, handleRefresh, editRowIndex, showCreateForm }) => {
  return (
      <div>
        <button className="control-button" onClick={handleUpdateRow} disabled={editRowIndex == null || showCreateForm}>
          <FaSave /> Update Data
        </button>
        <button className="control-button" onClick={() => setShowCreateForm(!showCreateForm)} disabled={editRowIndex !== null}>
          <FaPlus /> Create
        </button>
        <button className="control-button" onClick={handleRefresh} disabled={editRowIndex !== null || showCreateForm}>
          <FaSync /> Refresh Data
        </button>
      </div>
  );
};

export default TableControls;
