import React from 'react';
import '../../styles/Card/ControlsComponent.scss';
import { FaSync, FaPlus } from 'react-icons/fa';
import SearchFilter from '../SearchFilter';

const ControlsComponent = ({ onRefresh, onCreate, globalFilter, setGlobalFilter }) => {
  const handleRefreshClick = () => {
    onRefresh();
  };

  const handleCreateClick = () => {
    onCreate();
  };

  return (
    <div className="controls-container">
      <SearchFilter globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
      <div>
        <button className="control-button" onClick={handleRefreshClick}>
          <FaSync /> Refresh Data
        </button>
        <button className="control-button" onClick={handleCreateClick}>
          <FaPlus /> Create New
        </button>
        {/* Otros controles adicionales si los hubiera */}
      </div>
    </div>
  );
};

export default ControlsComponent;
