// src/Routes/PrivateRoutes.js

import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import Layout from '../components/layout/Layout';
import Home from '../Pages/Home';
import CronMain from '../Pages/Cron/CronMain';
import CronTable from '../Pages/Cron/CronTable';
import MarketMain from '../Pages/Market/MarketMain';
import ShopMain from '../Pages/Market/Shop/ShopMain';
import ShopTable from '../Pages/Market/Shop/ShopTable';
import StrategyMain from '../Pages/Market/Strategy/StrategyMain';
import StrategyList from '../Pages/Market/Strategy/StrategyList';
import { toast } from 'react-toastify';

const PrivateRoutes = () => {

  const showNotification = (message, type) => {
    switch (type) {
      case 'info':
        toast.info(message);
        break;
      case 'warning':
        toast.warning(message);
        break;
      case 'error':
        toast.error(message);
        break;
      default:
        toast(message);
    }
  };
  
  return (
    <Routes>
      <Route path="/" element={<PrivateRoute element={<Layout />}/>}>
        <Route path="home" element={<Home />} />
        <Route path="cron" element={<CronMain />}>
          <Route path="configuration" element={<CronTable showNotification={showNotification} />} />
        </Route>
        <Route path="market" element={<MarketMain />}>
          <Route path="shop" element={<ShopMain />}>
            <Route path="configuration" element={<ShopTable showNotification={showNotification} />} />
          </Route>
          <Route path="strategy" element={<StrategyMain />}>
            <Route path="configuration" element={<StrategyList showNotification={showNotification} />} />
          </Route>
        </Route>
        
      </Route>
      <Route path="*" element={<Navigate to="/notfound" />} />

    </Routes>
  );
};

export default PrivateRoutes;
