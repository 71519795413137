import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../Auth/AuthContext';

const PrivateRoute = ({ element }) => {
  const { isLoggedIn } = useAuth();

  if(!isLoggedIn){
    return <Navigate to="/login" replace />
  }

  return element
};

export default PrivateRoute;