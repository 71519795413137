import React, { useState, useMemo } from 'react';
import CardComponent from './CardComponent';
import CardModalComponent from './CardModalComponent';
import ControlsComponent from './ControlsComponent';

const CardGrid = ({ data, attributesInfo, onRefresh, onEdit, onDelete, onCreate, extraInfoTitle }) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [globalFilter, setGlobalFilter] = useState('');
  const [modalMode, setModalMode] = useState('edit'); // Modo de modal: 'edit' o 'create'

  const handleCardClick = (item) => {
    setSelectedItem(item);
    setModalMode('edit');
  };

  const handleModalClose = () => {
    setSelectedItem(null);
    setModalMode('edit'); // Volver al modo de edición por defecto al cerrar el modal
  };

  const handleRefresh = () => {
    onRefresh();
  };

  const handleCreateMode = () => {
    setSelectedItem({});
    setModalMode('create');
  };
  const handleCreate = async (data) => {
    await onCreate(data)
  };

  const handleSave = async (data) => {
    try {
      if (modalMode === 'create') {
        // Lógica para guardar el nuevo registro en el backend
        console.log('Guardando nuevo registro:', data);
        // Aquí deberías llamar a la función para crear un nuevo registro en el backend
      } else if (modalMode === 'edit') {
        // Lógica para editar el registro existente
        console.log('Editando registro:', data);
        await onEdit(data);
      }
      setSelectedItem(null);
    } catch (error) {
      console.error('Error al guardar/editar:', error);
    }
  };


  const filteredData = useMemo(() => {
    if (!globalFilter) {
      return data;
    }
    const lowerCaseFilter = globalFilter.toLowerCase();
    const filter = data.filter(item =>
      Object.keys(item).some(key =>
        String(item[key]).toLowerCase().includes(lowerCaseFilter)
      )
    );
    return filter;
  }, [data, globalFilter]);

  return (
    <div>
      <ControlsComponent
        onRefresh={handleRefresh}
        onCreate={handleCreateMode} // Pasamos la función handleCreate a ControlsComponent
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
      />
      <div className="card-grid">
        {filteredData.map((item) => (
          <CardComponent
            key={item.id}
            data={item}
            attributesInfo={attributesInfo}
            onClick={handleCardClick}
            onEdit={onEdit}
            onDelete={onDelete}
          />
        ))}
        {selectedItem && (
          <CardModalComponent
            data={selectedItem}
            attributesInfo={attributesInfo}
            onClose={handleModalClose}
            onSave={handleSave} // Pasamos la función handleSave a CardModalComponent
            onEdit={onEdit}
            onDelete={onDelete}
            onCreate={handleCreate}
            title={modalMode === 'create' ? 'Nuevo Registro' : extraInfoTitle}
            isNew={modalMode === 'create'} // Propiedad isNew para indicar si es un nuevo registro
          />
        )}
      </div>
    </div>
  );
};

export default CardGrid;
