import React, { useState, useEffect } from 'react';
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useSortBy,
  useRowState
} from 'react-table';
import '../../styles/PaginatedTable/PaginatedTable.scss';

import SearchFilter from '../SearchFilter';
import TableControls from './TableControls';
import CreateForm from './CreateForm';
import PaginationControls from './PaginationControls';
import DataTable from './DataTable';
import ConfirmDeleteModal from '../ConfirmDeleteModal';

const PaginatedTable = ({
  columnsConfig,
  data,
  fetchData,
  moreInfoText,
  moreInfoKey,
  handleEdit,
  handleRemove,
  handleCreate
}) => {
  const [globalFilter, setGlobalFilter] = useState('');
  const [rowInfo, setRowInfo] = useState(null);
  const [editedData, setEditedData] = useState(data);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [editRowIndex, setEditRowIndex] = useState(null);
  const [deleteRow, setDeleteRow] = useState(null);
  const [editedRowData, setEditedRowData] = useState({});
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [newItem, setNewItem] = useState({});
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false); // Nuevo estado para el modal de confirmación

  

  useEffect(() => {
    setEditedData(data);
  }, [data]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setGlobalFilter: setFilter,
    state: { pageIndex },
    previousPage,
    nextPage,
    canPreviousPage,
    canNextPage,
    pageOptions
  } = useTable(
    {
      columns: columnsConfig,
      data: editedData,
      initialState: { pageIndex: 0 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowState
  );

  useEffect(() => {
    setFilter(globalFilter);
  }, [globalFilter, setFilter]);

  const handleInputChange = (e, columnId) => {
    if (!e || !e.target) {
      console.error('Event object or event target is undefined:', e);
      return;
    }
  
    const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    setEditedRowData({
      ...editedRowData,
      [columnId]: value
    });
  };
  

  const handleNewInputChange = (e, columnId, type = null) => {
    if (type === 'json') {
      const value = e.target.value;
      try {
        const parsedValue = JSON.parse(value);
        setNewItem({
          ...newItem,
          [columnId]: parsedValue
        });
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    } else {
      const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
      setNewItem({
        ...newItem,
        [columnId]: value
      });
    }
  };

  const handleEditRow = (rowIndex) => {
    if (editRowIndex === rowIndex) {
      setEditRowIndex(null);
      setEditedRowData({});
      setSelectedRowIndex(null);
      setRowInfo(null);
    } else {
      setEditRowIndex(rowIndex);
      const row = page[rowIndex];
      setEditedRowData(row.original);
      setSelectedRowIndex(null);
      setRowInfo(null);
    }
  };

  const handleRemoveRow = async (row) => {
    if (row.id !== -1) {
      try {
        setDeleteRow(row)
        // Mostrar modal de confirmación antes de eliminar
        setShowConfirmDeleteModal(true);
      } catch (error) {
        console.error("Error removing row:", error);
      }
    }
  };

  const handleConfirmDelete = async () => {
    try {
      await handleRemove(deleteRow);
      setShowConfirmDeleteModal(false);
      fetchData();
    } catch (error) {
      console.error("Error removing row:", error);
    }
  };

  const handleCreateItem = async () => {
    try {
      await handleCreate(newItem);
      setNewItem({});
      fetchData();
    } catch (error) {
      console.error("Error creating item:", error);
    }
  };

  const handleUpdateRow = async () => {
    try {
      await handleEdit(editedRowData);
      setEditRowIndex(null);
      setEditedRowData({});
      fetchData();
    } catch (error) {
      console.error("Error updating row:", error);
    }
  };

  const toggleRowInfo = (index, rowData) => {
    console.log(`index:${index}|selectedRowIndex:${selectedRowIndex}`)
    if (selectedRowIndex === index) {
      setSelectedRowIndex(null);
      setRowInfo(null);
    } else {
      setSelectedRowIndex(index);
      setRowInfo(rowData);
    }
  };

  const handleCloseCreateForm = () => {
    setShowCreateForm(false);
  };

  const renderCellData = (value, dataType) => {
    switch (dataType) {
      case 'boolean':
        return value ? 'Yes' : 'No';
      case 'datetime':
        return new Date(value).toGMTString();
      case 'date':
        return new Date(value).toGMTString().slice(0, 19);
      default:
        return value;
    }
  };

  const handleInputJsonChange = (e, columnId) => {
    const value = e.target.value;
    try {
      const parsedValue = JSON.parse(value);
      setEditedRowData({
        ...editedRowData,
        [columnId]: parsedValue
      });
    } catch (error) {
      console.error("Error parsing JSON:", error);
    }
  };

  const handleCancelDelete = () => {
    setShowConfirmDeleteModal(false);
  };

  const renderEditCell = (column, value, onChange, isEditable) => {
    switch (column.dataType) {
      case 'boolean':
        return (
          <input
            type="checkbox"
            checked={value || false}
            onChange={onChange}
            readOnly={!isEditable}
          />
        );
      case 'date':
        return (
          <input
            type="date"
            value={value ? new Date(value).toISOString().slice(0, 10) : ''}
            onChange={onChange}
            readOnly={!isEditable}
          />
        );
      case 'datetime':
        return (
          <input
            type="datetime-local"
            value={value ? new Date(value).toISOString().slice(0, 16) : ''}
            onChange={onChange}
            readOnly={!isEditable}
          />
        );
      case 'textarea':
        return (
          <textarea
            value={value || ''}
            onChange={onChange}
            readOnly={!isEditable}
          />
        );
      case 'select':
        return (
          <select value={value} onChange={onChange} disabled={!isEditable}>
            {column.options && column.options.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        );
      default:
        return (
          <input
            type="text"
            value={value || ''}
            onChange={onChange}
            readOnly={!isEditable}
          />
        );
    }
  };

  return (
    <div className="table-container">
      <div className="table-controls">
        <SearchFilter globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />

        <TableControls
          handleUpdateRow={handleUpdateRow}
          setShowCreateForm={setShowCreateForm}
          handleRefresh={fetchData}
          editRowIndex={editRowIndex}
          showCreateForm={showCreateForm}
        />
      </div>
      {showCreateForm && (
        <CreateForm
          columnsConfig={columnsConfig}
          handleCreateItem={handleCreateItem}
          handleNewInputChange={handleNewInputChange}
          newItem={newItem}
          moreInfoKey={moreInfoKey}
        />
      )}

      <DataTable
        getTableProps={getTableProps}
        getTableBodyProps={getTableBodyProps}
        headerGroups={headerGroups}
        page={page}
        prepareRow={prepareRow}
        handleEditRow={handleEditRow}
        handleRemoveRow={handleRemoveRow}
        editRowIndex={editRowIndex}
        renderEditCell={renderEditCell}
        renderCellData={renderCellData}
        toggleRowInfo={toggleRowInfo}
        selectedRowIndex={selectedRowIndex}
        moreInfoText={moreInfoText}
        moreInfoKey={moreInfoKey}
        columnsConfig={columnsConfig}
        handleInputChange={handleInputChange}
        editedRowData={editedRowData}
        handleInputJsonChange={handleInputJsonChange}
        rowInfo={rowInfo}
      />

      <PaginationControls
        previousPage={previousPage}
        nextPage={nextPage}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        pageIndex={pageIndex}
        pageOptions={pageOptions}
      />

      <ConfirmDeleteModal
        isOpen={showConfirmDeleteModal}
        onConfirm={handleConfirmDelete}
        onCancel={handleCancelDelete}
      />
    </div>
  );
};

export default PaginatedTable;
