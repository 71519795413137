// UserMenu.jsx

import React from 'react';
import { Link, useNavigate  } from 'react-router-dom';
import '../../styles/Layout/UserMenu.scss';
import { useAuth } from '../../Auth/AuthContext';


const UserMenu = () => {

  const navigate = useNavigate(); // Usamos useNavigate para redireccionar
  const { logout } = useAuth();

  const handleLogout = () => {
    // Eliminar la cookie de sesión
    logout()
    // redirigir al usuario a la página principal ("/")
    navigate('/');
  };

  return (
    <div className="user-menu">
      <ul className="menu-list">
        <li>
          <Link to="/perfil">Perfil</Link>
        </li>
        <li>
          <button onClick={handleLogout}>Logout</button>
        </li>
      </ul>
    </div>
  );
};

export default UserMenu;
