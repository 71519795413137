// DefaultView.jsx

import React from 'react';
import '../styles/DefaultView.scss';

const DefaultView = () => {
  return (
    <div >
        <h2>RESOURCE NOT FOUND</h2>
    </div>
  );
};

export default DefaultView;
