// src/App.js

import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, Link } from 'react-router-dom';
import './App.scss';
import LoginForm from './Pages/Login/LoginForm';
import DefaultView from './Pages/DefaultView';
import SimpleLayout from './components/SimpleLayout';
import { AuthProvider } from './Auth/AuthContext';
import NotFound from './Pages/NotFound';


import PrivateRoutes from './Routes/PrivateRoutes';

function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
        <Route path="/" element={<SimpleLayout />}>
          <Route path="/" element={<DefaultView />} />
          <Route path="/login" element={<LoginForm />} />
          <Route path="/notfound" element={<NotFound />} />
        </Route>


          <Route path="/*" element={<PrivateRoutes />} />

          <Route path="*" element={<Navigate to="/notfound" />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
