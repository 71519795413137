// LoginForm.jsx

import React, { useState, useEffect  } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import '../../styles/LoginForm.scss';
import { useAuth } from '../../Auth/AuthContext';

const LoginForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const { isLoggedIn, login } = useAuth();
  useEffect(() => {
    if(isLoggedIn){
      navigate('/home');
    }
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/user/login`, {
        email,
        password,
      });
      
      // Si la respuesta es exitosa, almacenar el token en una cookie de sesión
      const token = response.data.user.token;
      Cookies.set('session_token', token);
      login()

      // Redirigir al usuario a la página "/main"
      navigate('/home'); // Utilizamos navigate en lugar de history.push
    } catch (error) {
      // Si hay un error, mostrar el mensaje de error al usuario
      setError('Usuario y/o contraseña incorrectos');
    }
  };

  return (
    <div className="login-container">
      <form className="login-form" onSubmit={handleSubmit}>
        <input
          type="email"
          placeholder="Correo electrónico"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          placeholder="Contraseña"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button type="submit">Iniciar sesión</button>
        {error && <p className="error-message">{error}</p>}
      </form>
    </div>
  );
};

export default LoginForm;
