import React, { useState } from 'react';
import '../../styles/Card/CardModalComponent.scss';
import ConfirmDeleteModal from '../ConfirmDeleteModal';

const CardModalComponent = ({ data, onClose, onEdit, onDelete, onCreate, attributesInfo, title, isNew }) => {
  const [editMode, setEditMode] = useState(false);
  const [editedData, setEditedData] = useState(data);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);

  const handleClose = () => onClose();

  const handleEdit = () => setEditMode(true);

  const handleSave = async () => {
    try {
      if(isNew){
        await onCreate(editedData);
      }else{
        await onEdit(editedData);
      }
      setEditMode(false);
    } catch (error) {
      console.error('Error al editar los datos:', error);
    }
  };

  const handleCreate = async () => {
    try {
      setEditMode(false);
    } catch (error) {
      console.error('Error al guardar los datos:', error);
    }
  };
  

  const handleDelete = () => setShowConfirmDelete(true);

  const confirmDelete = () => {
    onDelete(data);
    setShowConfirmDelete(false);
    handleClose();
  };

  const cancelDelete = () => setShowConfirmDelete(false);

  const handleBackgroundClick = (event) => {
    if (event.target.classList.contains('card-modal-container')) {
      onClose();
    }
  };

  const handleInputChange = (key, value) => {
    setEditedData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };

  const renderInput = (key, value, attributeInfo) => {
    switch (attributeInfo.dataType) {
      case 'integer':
        return (
          <input
            type="number"
            value={value}
            onChange={(e) => handleInputChange(key, parseInt(e.target.value))}
            className="input-field"
          />
        );
      case 'bool':
        return (
          <input
            type="checkbox"
            checked={value}
            onChange={(e) => handleInputChange(key, e.target.checked)}
            className="input-field-checkbox"
          />
        );
      case 'float':
        return (
          <input
            type="number"
            step="0.01"
            value={value}
            onChange={(e) => handleInputChange(key, parseFloat(e.target.value))}
            className="input-field"
          />
        );
      case 'datetime':
        return (
          <input
            type="datetime-local"
            value={new Date(value).toISOString().slice(0, 16)}
            onChange={(e) => handleInputChange(key, e.target.value)}
            className="input-field"
          />
        );
      case 'shortText':
        return (
          <input
            type="text"
            value={value}
            onChange={(e) => handleInputChange(key, e.target.value)}
            className="input-field"
          />
        );
      case 'longText':
        return (
          <textarea
            value={value}
            onChange={(e) => handleInputChange(key, e.target.value)}
            className="input-field"
          />
        );
      case 'json':
        return (
          <textarea
            value={JSON.stringify(value, null, 2)}
            onChange={(e) => handleInputChange(key, JSON.parse(e.target.value))}
            className="input-field"
          />
        );
      case 'select':
        console.log(attributeInfo.options[0].value)
        return (
          <select
            value={value}
            onChange={(e) => handleInputChange(key, e.target.value)}
            className="input-field"
          >
            {attributeInfo.options.map(option => (
              <option key={option.key} value={option.key}>
                {option.value}
              </option>
            ))}
          </select>
        );
      default:
        return (
          <input
            type="text"
            value={value}
            onChange={(e) => handleInputChange(key, e.target.value)}
            className="input-field"
          />
        );
    }
  };

  const renderAttributeValue = (key, value) => {
    const attributeInfo = attributesInfo[key];

    if (!attributeInfo) return value;

    // Si está en modo de edición, renderiza inputs editables
    if ((editMode || isNew) && attributeInfo.editable) {
      return renderInput(key, editedData[key], attributeInfo);
    }

    // Si no está en modo de edición o el campo no es editable, muestra el valor normal
    switch (attributeInfo.dataType) {
      case 'integer':
        return <span>{parseInt(value)}</span>;
      case 'bool':
        return (
          <input
            type="checkbox"
            checked={value}
            disabled
            className="input-field-checkbox"
          />
        );
      case 'float':
        return <span>{parseFloat(value)}</span>;
      case 'datetime':
        return <span>{new Date(value).toLocaleString()}</span>;
      case 'shortText':
        return <span>{value}</span>;
      case 'longText':
        return <span>{value}</span>;
      case 'json':
        return <pre>{JSON.stringify(value, null, 2)}</pre>;
      case 'select':
        const selectedOption = attributeInfo.options.find(option => option.key === value);
        return <span>{selectedOption ? selectedOption.value : value}</span>;
      default:
        return <span>{value}</span>;
    }
  };

  return (
    <div className="card-modal-container" onClick={handleBackgroundClick}>
      <div className="card-modal-content">
        <div className="card-modal-header">
          <h2 className="card-modal-title">{title}</h2>
          <button className="card-modal-close" onClick={handleClose}>X</button>
        </div>
        <div className="card-modal-body">
          {Object.keys(attributesInfo).map((key) => (
            <div key={key} className="card-modal-attribute">
              <span className="card-modal-attribute-name">{attributesInfo[key].displayName}: </span>
              <span className="card-modal-attribute-value">{renderAttributeValue(key, editedData[key])}</span>
            </div>
          ))}
        </div>
        <div className="card-modal-footer">
          {editMode ? (
            <button className="card-modal-button card-modal-button-primary" onClick={handleSave}>Guardar</button>
          ) : 
            <button className="card-modal-button card-modal-button-primary" onClick={handleEdit}>{isNew ? "Crear" : "Editar"}</button>
          }
          <button className="card-modal-button card-modal-button-danger" onClick={handleDelete} disabled={editMode}>Eliminar</button>
        </div>
      </div>
      {showConfirmDelete && (
        <ConfirmDeleteModal
          isOpen={showConfirmDelete}
          onConfirm={confirmDelete}
          onCancel={cancelDelete}
          message="¿Estás seguro de que deseas eliminar este elemento?"
        />
      )}
    </div>
  );
};

export default CardModalComponent;
