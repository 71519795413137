// Sidebar.jsx

import React from 'react';
import '../../styles/Layout/Sidebar.scss';
import MenuItem from './MenuItem';

const Sidebar = ({isSidebarVisible}) => {
  return (
    <div className={`sidebar ${isSidebarVisible ? '' : 'sidebar-closed'}`}>
      <MenuItem
        title="Cron"
        icon="cron"
        subMenuItems={[
          { title: "Configuración", to: "/cron/configuration" },
        ]}
      />
      <MenuItem
        title="Market"
        icon="market"
        subMenuItems={[
          { title: "Shop", to: "/market/shop/configuration" },
          { title: "Strategy", to: "/market/strategy/configuration" },
        ]}
      />
      {/* Puedes agregar más MenuItem aquí */}
    </div>
  );
};

export default Sidebar;
