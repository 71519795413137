// DefaultView.jsx

import React from 'react';
import '../styles/DefaultView.scss';
import { Link } from 'react-router-dom';

const DefaultView = () => {
  return (
    <div >
        <Link to="/login">Iniciar sesión</Link>
    </div>
  );
};

export default DefaultView;
