// DefaultView.jsx

import React from 'react';
import { Link, Outlet } from 'react-router-dom';
import '../styles/DefaultView.scss';

const SimpleLayout = () => {
  return (
    <div className="default-view">
      <div className="centered">
        <img src="logo.png" alt="Company Logo" />
        <h1>BUSINESS INTELLIGENCE</h1>
        {/* <Link to="/login">Iniciar sesión</Link> */}
          <Outlet />
      </div>
      <footer>
        <p>&copy; {new Date().getFullYear()} Maxmovil S.L.U. Todos los derechos reservados.</p>
      </footer>
    </div>
  );
};

export default SimpleLayout;
