// Layout.jsx
import React, { useState } from 'react';
import Header from './Header';
import Sidebar from './Sidebar';
import Notification from '../notifications/Notification';
import '../../styles/Layout/Layout.scss';
import { Outlet } from 'react-router-dom';

const Layout = () => {
  const [isSidebarVisible, setIsSidebarVisible] = useState(true);

  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
  };



  return (
    <div className="layout">
      <Header toggleSidebar={toggleSidebar} />
      <Notification />
      <div className="content">
        <Sidebar isSidebarVisible={isSidebarVisible}/>
        <div className={`main ${isSidebarVisible ? '' : 'full-width'}`}>
          <Outlet/>
        </div>
      </div>
    </div>
  );
};

export default Layout;
