// CronTable.jsx
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import PaginatedTable from '../../components/PaginatedTable/PaginatedTable';
import { FaInfoCircle } from 'react-icons/fa';
import Cookies from 'js-cookie';
import '../../styles/Pages/Cron/CronTable.scss';

const columnsConfig = [
  { Header: 'Active', accessor: 'is_active', dataType: 'boolean', isEditable:true },
  { Header: 'Name', accessor: 'name', dataType: 'text', isEditable:true },
  { Header: 'Expression', accessor: 'cron_expression', dataType: 'text', isEditable:true },
  { Header: 'Next execution', accessor: 'next_execution', dataType: 'datetime', isEditable:true },
  { Header: 'Last execution', accessor: 'last_execution', dataType: 'datetime', isEditable:false },
  { Header: 'Action', accessor: 'moreInfo',dataType: 'textarea', moreinfoKey: 'action_list', isEditable:true, Cell: ({ row }) => <FaInfoCircle /> },
];

const CronTable = ({ showNotification }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = async () => {
    const token = Cookies.get('session_token');

    const url = `${process.env.REACT_APP_API_URL}/cron`;
    try {
      const response = await axios.get(url, {
        headers: {
          'Content-Type': 'application/json',
          'token': token,
        }
      });
      setData(response.data.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const deleteData = async (cron_id) => {
    const token = Cookies.get('session_token');

    const url = `${process.env.REACT_APP_API_URL}/cron/${cron_id}`;
    try {
      await axios.delete(url, {
        headers: {
          'Content-Type': 'application/json',
          'token': token,
        }
      });
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const updateData = async (updatedData) => {
    const token = Cookies.get('session_token');

    const url = `${process.env.REACT_APP_API_URL}/cron/${updatedData._id}`;
    try {
      await axios.put(url, updatedData, {
        headers: {
          'Content-Type': 'application/json',
          'token': token,
        }
      });
      showNotification('Data updated successfully!', 'info');
      return true;
    } catch (error) {
      showNotification('Error updating data!', 'error');
      return false;
    } finally {
      setLoading(false);
    }
  };

  
  const createData = async (newData) => {
    const token = Cookies.get('session_token');

    const url = `${process.env.REACT_APP_API_URL}/cron`;
    console.log(newData)
    try {
      await axios.post(url, newData, {
        headers: {
          'Content-Type': 'application/json',
          'token': token,
        }
      });
      showNotification('Data updated successfully!', 'info');
      return true;
    } catch (error) {
      showNotification('Error updating data!', 'error');
      return false;
    } finally {
      setLoading(false);
    }
  };

  const handleRefresh = async (cron_id) => {
    setLoading(true);
    try {
      await fetchData();
      showNotification('Data fetched successfully!', 'info');
      return true;
    } catch (error) {
      showNotification('Error!', 'error');
      return false;
    }
  };

  const handleEdit = async (editedRowData) => {
    setLoading(true);
    try {
      await updateData(editedRowData);
      return true;
    } catch (error) {
      showNotification('Error!', 'error');
      return false;
    }
  };

  const handleCreate = async (newRowData) => {
    setLoading(true);
    try {
      await createData(newRowData);
      return true;
    } catch (error) {
      showNotification('Error!', 'error');
      return false;
    }
  };

  const handleRemove = async (cron) => {
    const cron_id = cron.original._id
    setLoading(true);
    try {
      // Realizar la eliminación en la base de datos
      await deleteData(cron_id);
      setData(prevData => data.filter(item => item._id !== cron_id));
      showNotification('Data deleted successfully!', 'info');
      return true;
    } catch (error) {
      showNotification('Error deleting data!', 'error');
      return false;
    } finally {
      setLoading(false); // Asegurarse de restablecer el estado de carga después de la operación
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <PaginatedTable
      columnsConfig={columnsConfig}
      data={data}
      fetchData={handleRefresh}
      moreInfoText="Action list"
      moreInfoKey="action_list"
      handleRemove={handleRemove}
      handleEdit={handleEdit}
      handleCreate={handleCreate}
    />
  );
};

export default CronTable;
