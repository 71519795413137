// columnsConfig.js
import { FaInfoCircle } from 'react-icons/fa';

const columnsConfig = [
  { Header: 'Active', accessor: 'is_active', dataType: 'boolean', isEditable:true },
  { Header: 'Name', accessor: 'name', dataType: 'text', isEditable:true },
  { Header: 'My Name', accessor: 'my_name', dataType: 'text', isEditable:true },
  { Header: 'Type', accessor: 'type', dataType: 'select', isEditable: true, options: [
    { value: 'mirakl', label: 'Mirakl' },
  ] },
  { Header: 'Shop ID', accessor: 'shop_id', dataType: 'text', isEditable:true },
  { Header: 'URL', accessor: 'url', dataType: 'text', isEditable:true },
  { Header: 'API Key', accessor: 'api_key', dataType: 'text', isEditable:true },
  { Header: 'Updated', accessor: 'updated_at', dataType: 'datetime', isEditable:false },
  { Header: 'Base data', accessor: 'moreInfo', dataType: 'text', moreinfoKey: 'base_data', isEditable:true, Cell: ({ row }) => <FaInfoCircle /> },
];

export default columnsConfig;
