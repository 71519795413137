// MainView.jsx

import React from 'react';
import { Outlet } from 'react-router-dom';
// import Layout from './Layout/Layout';

const MarketMain = () => {
  return (
      <div className="main-content">
        {/* <h1>Market</h1> */}
          <Outlet />
      </div>
  );
};

export default MarketMain;
