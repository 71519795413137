import React, { useState, useRef, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import '../../styles/Layout/MenuItem.scss';
import { FaChevronRight } from 'react-icons/fa';
import classNames from 'classnames';

const MenuItem = ({ title, icon, subMenuItems }) => {
  const [showSubMenu, setShowSubMenu] = useState(false);
  const subMenuRef = useRef(null);

  const toggleSubMenu = () => {
    setShowSubMenu(prevState => !prevState);
  };

  useEffect(() => {
    if (subMenuRef.current) {
      subMenuRef.current.style.maxHeight = showSubMenu ? `${subMenuRef.current.scrollHeight}px` : '0';
    }
  }, [showSubMenu]);

  return (
    <div className={classNames('menu-item', { 'open': showSubMenu })}>
      <div className="menu-link" onClick={toggleSubMenu}>
        <span className={classNames('sub-menu-toggle', { 'rotated': showSubMenu })}>
          <FaChevronRight />
        </span>
        <span>{title}</span>
      </div>
      <div ref={subMenuRef} className="sub-menu">
        {subMenuItems.map((item, index) => (
          <div key={index}>
            <NavLink to={item.to} activeClassName="active">
              {item.title}
            </NavLink>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MenuItem;
