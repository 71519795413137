import React from 'react';

const PaginationControls = ({ previousPage, nextPage, canPreviousPage, canNextPage, pageIndex, pageOptions }) => {
  return (
    <div className="pagination">
      <button onClick={() => previousPage()} disabled={!canPreviousPage}>
        {'<'}
      </button>
      <span>
        Page{' '}
        <strong>
          {pageIndex + 1} of {pageOptions.length}
        </strong>{' '}
      </span>
      <button onClick={() => nextPage()} disabled={!canNextPage}>
        {'>'}
      </button>
    </div>
  );
};

export default PaginationControls;
