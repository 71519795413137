// CardComponent.jsx

import React from 'react';
import '../../styles/Card/CardComponent.scss'

const CardComponent = ({ data, attributesInfo, onClick }) => {
  const handleClick = () => {
    onClick(data);
  };

  const renderValue = (value, dataType) => {
    switch (dataType) {
      case 'integer':
        return <span>{parseInt(value)}</span>;
      case 'bool':
        return <span>{value ? 'Yes' : 'No'}</span>;
      case 'float':
        return <span>{parseFloat(value)}</span>;
      case 'datetime':
        return <span>{new Date(value).toLocaleString()}</span>;
      case 'shortText':
        return <span>{value}</span>;
      case 'longText':
        return <span>{value}</span>; // Podrías considerar truncar el texto si es demasiado largo
      case 'json':
        return <pre>{JSON.stringify(value, null, 2)}</pre>;
      default:
        return <span>{value}</span>;
    }
  };

  return (
    <div className="card" onClick={handleClick}>
      {Object.keys(data).map((key) => {
        const attributeInfo = attributesInfo[key];
        if (attributeInfo && attributeInfo.partialInfo) {
          return (
            <div key={key} className="partial-info">
              <span>{attributeInfo.displayName}: </span>
              {renderValue(data[key], attributeInfo.dataType)}
            </div>
          );
        }
        return null;
      })}
    </div>
  );
};

export default CardComponent;
