// Header.jsx

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import UserMenu from './UserMenu';
import '../../styles/Layout/Header.scss';
import { FaBars } from 'react-icons/fa';

const Header = ({ toggleSidebar }) => {
  const [showUserMenu, setShowUserMenu] = useState(false);
  const navigate = useNavigate();

  const toggleUserMenu = () => {
    setShowUserMenu(prevState => !prevState);
  };

  const redirectToMain = () => {
    navigate('/home');
  };

  return (
    <div className="header">
      <div className="header">
        <button className="toggle-sidebar" onClick={toggleSidebar}>
          <FaBars />
        </button>
        <div className="logo" onClick={redirectToMain}>
          <img src={`${process.env.PUBLIC_URL}/logo.png`} alt="Company Logo" />
          <span>Business Intelligence</span>
        </div>
      </div>
      <div className="user-menu-container">
        <div className="user-icon" onClick={toggleUserMenu}>
          <img src={`${process.env.PUBLIC_URL}/user-icon.png`} alt="User" />
          {showUserMenu && <UserMenu />}
        </div>
      </div>
    </div>
  );
};

export default Header;
