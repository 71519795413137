// StrategyList.jsx

import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import CardGrid from "../../../components/Card/CardGrid";
import AttributesInfo from './AttributesInfo';

const StrategyList = ({ showNotification }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const strategy_id = Cookies.get('current_strategy') || 2;
  const base_url = `${process.env.REACT_APP_API_URL}/market/strategy/${strategy_id}`;
  const token = Cookies.get('session_token');

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(base_url, {
        headers: {
          'Content-Type': 'application/json',
          token: token,
        },
      });
      setData(response.data.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [base_url, token]);

  const handleRefresh = async () => {
    setLoading(true);
    try {
      await fetchData();
      showNotification('Data fetched successfully!', 'info');
    } catch (error) {
      showNotification('Error!', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleDataChange = async (url, method, data = null) => {
    setLoading(true);
    try {
      await axios({ url, method, data, headers: { 'Content-Type': 'application/json', 'token': token } });
      showNotification('Operation successful!', 'info');
      return true;
    } catch (error) {
      setError(error);
      showNotification(`Error during ${method} operation!`, 'error');
      setError(error);
      return false;
    } finally {
      setLoading(false);
    }
  }
  const handleEdit = async (editedRowData) => handleDataChange(`${base_url}/${editedRowData.id}`, 'PUT', editedRowData);
  const handleCreate = async (newRowData) => handleDataChange(`${base_url}`, 'POST', newRowData);
  const handleRemove = async (strategy) => {  
      const success = await handleDataChange(`${base_url}/${strategy.id}`, 'DELETE',{
      headers: {
        'Content-Type': 'application/json',
        'token': token,
      }
    });
    if (success) setData(prevData => data.filter(item => item.id !== strategy.id));
    return success;
  };

  return (
    <div>
      {loading && <div>Loading...</div>}
      {error && <div>Error: {error.message}</div>}
      <CardGrid
        data={data}
        attributesInfo={AttributesInfo}
        onRefresh={handleRefresh}
        onEdit={handleEdit}
        onDelete={handleRemove}
        onCreate={handleCreate}
        extraInfoTitle="Extra information"
      />
    </div>
  );
};

export default StrategyList;
