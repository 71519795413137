import React from 'react';
import { FaSave, FaTimes } from 'react-icons/fa';

const CreateForm = ({ columnsConfig, handleCreateItem, handleNewInputChange, newItem, moreInfoKey }) => {
    const renderEditCell = (column, value, onChange, isEditable) => {
        switch (column.dataType) {
          case 'boolean':
            return (
              <input
                type="checkbox"
                checked={value || false}
                onChange={onChange}
                readOnly={!isEditable}
              />
            );
          case 'date':
            return (
              <input
                type="date"
                value={value ? new Date(value).toISOString().slice(0, 10) : ''}
                onChange={onChange}
                readOnly={!isEditable}
              />
            );
          case 'datetime':
            return (
              <input
                type="datetime-local"
                value={value ? new Date(value).toISOString().slice(0, 16) : ''}
                onChange={onChange}
                readOnly={!isEditable}
              />
            );
          case 'textarea':
            return (
              <textarea
                value={value || ''}
                onChange={onChange}
                readOnly={!isEditable}
              />
            );
          case 'select':
            return (
              <select value={value} onChange={onChange} disabled={!isEditable}>
                {column.options && column.options.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            );
          default:
            return (
              <input
                type="text"
                value={value || ''}
                onChange={onChange}
                readOnly={!isEditable}
              />
            );
        }
      };

  return (
    <div className="create-form-container">
      <div className="create-form">
        <h2>Create New Record</h2>
        <form>
          {columnsConfig.map(column => column.isEditable && (
            <div key={column.accessor} className={`form-field ${column.accessor === 'moreInfo' ? 'more-info-field' : ''}`}>
              <label>{column.Header}:</label>
              {column.accessor === 'moreInfo'
                ? renderEditCell(column, newItem[moreInfoKey], (e) => handleNewInputChange(e, moreInfoKey, column.dataType), column.isEditable)
                : renderEditCell(column, newItem[column.accessor], (e) => handleNewInputChange(e, column.accessor), column.isEditable)
              }
            </div>
          ))}
          <div className="form-buttons">
            <button className="form-submit" type="button" onClick={handleCreateItem}>
              <FaSave /> Save
            </button>
            <button className="form-cancel" type="button">
              <FaTimes /> Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateForm;
