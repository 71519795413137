// ShopTable.jsx
import React, { useEffect, useState,useCallback  } from 'react';
import axios from 'axios';
import PaginatedTable from '../../../components/PaginatedTable/PaginatedTable';
import Cookies from 'js-cookie';
import columnsConfig from './columnsConfig';
// import '../../../styles/Pages/Shop/ShopTable.scss'

const ShopTable = ({ showNotification }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const base_url = `${process.env.REACT_APP_API_URL}/market/shop`
  const token = Cookies.get('session_token');


  const handleRefresh = async (cron_id) => {
    setLoading(true);
    try {
      await fetchData();
      showNotification('Data fetched successfully!', 'info');
      return true;
    } catch (error) {
      showNotification('Error!', 'error');
      return false;
    }
  };

  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(base_url, {
        headers: {
          'Content-Type': 'application/json',
          'token': token,
        }
      });
      setData(response.data.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  }, [base_url, token]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleDataChange = async (url, method, data = null) => {
    setLoading(true);
    try {
      await axios({ url, method, data, headers: { 'Content-Type': 'application/json', 'token': token } });
      showNotification('Operation successful!', 'info');
      return true;
    } catch (error) {
      setError(error);
      showNotification(`Error during ${method} operation!`, 'error');
      setError(error);
      return false;
    } finally {
      setLoading(false);
    }
  };

  const handleCreate = async (newData) => handleDataChange(base_url, 'POST', newData);
  const handleEdit = async (editedRowData) => handleDataChange(`${base_url}/${editedRowData.id}`, 'PUT', editedRowData);
  const handleRemove = async (shop) => {  
      const success = await handleDataChange(`${base_url}/${shop.original.id}`, 'DELETE',{
      headers: {
        'Content-Type': 'application/json',
        'token': token,
      }
    });
    if (success) setData(prevData => data.filter(item => item.id !== shop.original.id));
    return success;
  };

  const renderContent = () => {
    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;
    return (
      <PaginatedTable
        columnsConfig={columnsConfig}
        data={data}
        fetchData={handleRefresh}
        moreInfoText="Base data"
        moreInfoKey="base_data"
        handleRemove={handleRemove}
        handleEdit={handleEdit}
        handleCreate={handleCreate}
      />
    );
  };

  return renderContent();
};

export default ShopTable;
