// MainView.jsx

import React from 'react';
import { Outlet } from 'react-router-dom';
// import Layout from './Layout/Layout';

const StrategyMain = () => {
  return (
      <div className="main-content">
        <h1>Strategy</h1>
          <Outlet />
      </div>
  );
};

export default StrategyMain;
