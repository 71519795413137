// SearchFilter.jsx

import React from 'react';
import { FaSearch } from 'react-icons/fa';

const SearchFilter = ({ globalFilter, setGlobalFilter }) => {
  return (
    <div className="search-container">
      <FaSearch />
      <input
        type="text"
        placeholder="Search..."
        value={globalFilter || ''}
        onChange={(e) => setGlobalFilter(e.target.value)}
      />
    </div>
  );
};

export default SearchFilter;
