// attributesInfo.jsx

const AttributesInfo = {
    id: {
      displayName: "ID",
      editable: false,
      partialInfo: true,
      dataType: "number",
    },
    is_active: {
      displayName: "Active",
      editable: true,
      partialInfo: true,
      dataType: "bool",
    },
    can_decreasing: {
      displayName: "Can decreasing",
      editable: true,
      partialInfo: true,
      dataType: "bool",
    },
    lower_limit_mode: {
      displayName: "Lower limit mode",
      editable: true,
      partialInfo: false,
      dataType: "select",
      options: [
        {key:'fix', value:'Fix'},
        {key:'relative', value:'Relative'}
      ]
    },
    lower_limit_ref: {
      displayName: "Lower limit ref",
      editable: true,
      partialInfo: false,
      dataType: "select",
      options: [
        {key:'bb_price', value:'Buybox price'},
        {key:'my_price', value:'My price'},
        {key:'original', value:'Original price'},
      ]
    },
    lower_limit_abs: {
      displayName: "Lower limit abs",
      editable: true,
      partialInfo: false,
      dataType: "float",
    },
    lower_limit_rel: {
      displayName: "Lower limit rel",
      editable: true,
      partialInfo: false,
      dataType: "float",
    },
    opt_nbb_abs: {
      displayName: "Opt nbb abs",
      editable: true,
      partialInfo: false,
      dataType: "float",
    },
    opt_nbb_rel: {
      displayName: "Opt nbb rel",
      editable: true,
      partialInfo: false,
      dataType: "float",
    },
    opt_nbb_ref: {
      displayName: "Opt nbb ref",
      editable: true,
      partialInfo: false,
      dataType: "select",
      options: [
        {key:'bb_price', value:'Buybox price'},
        {key:'my_price', value:'My price'},
        {key:'original', value:'Original price'},
      ]
    },
    lower_cost_limit: {
      displayName: "Lower cost limit",
      editable: true,
      partialInfo: false,
      dataType: "float",
    },



    can_increasing: {
      displayName: "Can increasing",
      editable: true,
      partialInfo: true,
      dataType: "bool",
    },
    upper_limit_mode: {
      displayName: "upper limit mode",
      editable: true,
      partialInfo: false,
      dataType: "select",
      options: [
        {key:'fix', value:'Fix'},
        {key:'relative', value:'Relative'}
      ]
    },
    upper_limit_ref: {
      displayName: "upper limit ref",
      editable: true,
      partialInfo: false,
      dataType: "select",
      options: [
        {key:'bb_price', value:'Buybox price'},
        {key:'my_price', value:'My price'},
        {key:'original', value:'Original price'},
      ]
    },
    upper_limit_abs: {
      displayName: "upper limit abs",
      editable: true,
      partialInfo: false,
      dataType: "float",
    },
    upper_limit_rel: {
      displayName: "upper limit rel",
      editable: true,
      partialInfo: false,
      dataType: "float",
    },
    opt_bb_abs: {
      displayName: "Opt bb abs",
      editable: true,
      partialInfo: false,
      dataType: "float",
    },
    opt_bb_rel: {
      displayName: "Opt bb rel",
      editable: true,
      partialInfo: false,
      dataType: "float",
    },
    opt_bb_ref: {
      displayName: "Opt bb ref",
      editable: true,
      partialInfo: false,
      dataType: "select",
      options: [
        {key:'bb_price', value:'Buybox price'},
        {key:'my_price', value:'My price'},
        {key:'original', value:'Original price'},
      ]
    },
    upper_cost_limit: {
      displayName: "upper cost limit",
      editable: true,
      partialInfo: false,
      dataType: "float",
    },

  };
  
  export default AttributesInfo;
  