// MainView.jsx

import React from 'react';
// import Layout from './Layout/Layout';

const Home = () => {
  return (
      <div className="main-content">
        <h1>HOME</h1>
      </div>
  );
};

export default Home;
