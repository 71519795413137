// ConfirmDeleteModal.jsx

import React from 'react';
import '../styles/ConfirmDeleteModal.scss';

const ConfirmDeleteModal = ({ isOpen, onConfirm, onCancel, message="¿Estás seguro de que deseas eliminar este elemento?" }) => {
  if (!isOpen) {
    return null;
  }
  return (
    <div className="confirm-delete-modal">
      <div className="confirm-delete-content">
        <p>{message}</p>
        <div className="confirm-delete-buttons">
          <button className="card-modal-button card-modal-button-danger" onClick={onConfirm}>Sí, eliminar</button>
          <button className="card-modal-button" onClick={onCancel}>Cancelar</button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmDeleteModal;
